.app-container {
    background-color: #1e1e1e;
    color: #fff;
    font-family: 'Arial', sans-serif;
    min-height: 100vh;
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}



.vertical-text {
    width: 2%;
    text-wrap: nowrap;
    position: fixed;
    font-size: 1.2rem;
    transform-origin: bottom left;
    transform: rotate(-90deg);
    bottom: 84px;
    left: 1.5rem;
    text-align: left;
    color: #919795;
    letter-spacing: .02em;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    margin-bottom: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-content {
    max-width: 1200px;
    width: 100%;
    padding: 20px;
    overflow: auto;
    display: grid !important;
}

/* Content grid using CSS Columns */
.content-grid {
    column-count: 1;
    column-gap: 20px;
}


@media (min-width: 1024px) {
    .content-grid {
        column-count: 2;
        column-gap: 20px;
    }
}

/* Ensure items don't break inside columns */
.content {
    display: inline-block;
    width: 100%;
    break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
    margin-bottom: 20px;
}

/* Rest of your CSS... */