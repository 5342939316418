.App {
    font-family: sans-serif;
    text-align: center;
}

body {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    --secondary: rgb(161, 161, 161);
    --divider: #343434;
}

* {
    box-sizing: border-box;
    font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText",
        Helvetica, Arial, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#root {
    display: flex;
    justify-content: center;
}

.screen {
    width: 100%;
    height: 100%;
}

.container {
    max-width: 990px;
    flex: 1 1 100%;
    padding: 15px 15px;
}

h1 {
    font-weight: bold;
    color: white;
    margin: 6px 0 12px;
}

.date {
    color: var(--secondary);
    font-size: 14px;
    text-transform: uppercase;
}

header {
    border-bottom: 1px solid var(--divider);
    position: relative;
}

.avatar {
    background: var(--divider);
    position: absolute;
    bottom: 12px;
    right: 0;
    overflow: hidden;
}

.avatar,
.avatar img {
    width: 40px;
    height: 40px;

}



ul,
li {
    list-style: none;
    padding: 0;
    margin: 0;
}


.card {
    background: #111 !important;
    height: min-content;
}

.cards-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.card-fixed {
    position: relative;
    padding: 25px;
    flex: 0 0 40%;
    height: min-content !important;
}

.card:nth-child(4n + 1),
.card:nth-child(4n + 4) {
    flex: 0 0 60%;
    max-width: 60%;
}

.card:nth-child(odd) {
    padding-left: 0;
}

.card:nth-child(even) {
    padding-right: 0;
}

.card-content-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    pointer-events: none;
}

.card-content-container.open {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 1;
    overflow: scroll;
    padding: 40px 0;
}

.card-content {
    pointer-events: auto;
    position: relative;
    background: #111;
    overflow: hidden;
    margin: 0 auto;
}

.open .card-content {
    height: auto;
    max-width: 700px;
    overflow: hidden;
    border-radius: 20px;
    background: #111;
}

.card-open-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.card-image-container {
    position: absolute;

    top: 0;
    left: 0;
    overflow: hidden;
    height: 420px;
    width: 100vw;
}

.category {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
}

.title-container {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

h2 {
    color: red;
    margin: 8px 0;
}

.category {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
}

.overlay {
    z-index: 1;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    will-change: opacity;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: 100%;
}

.overlay a {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100vw;
    left: 50%;
}

.content-container {
    padding: 460px 35px 35px 35px;
    max-width: 700px;
    width: 90vw;
}

p {
    color: #9d9ca1;
    font-size: 20px;
    line-height: 28px;
}

@media only screen and (max-width: 800px) {
    .card {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .card:nth-child(4n + 1),
    .card:nth-child(4n + 4) {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .card {
        flex: 1 0 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .card:nth-child(4n + 1),
    .card:nth-child(4n + 4) {
        flex: 1 0 100%;
        max-width: 100%;
    }

    .card-content-container.open {
        padding: 0;
    }
}