*,
:before,
:after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: var(--un-default-border-color, #e5e7eb)
}



html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -moz-tab-size: 4;
    tab-size: 4;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
    height: 100vh;
    background-color: rgb(17 17 17);
}


body {
    margin: 0;
    line-height: inherit;
    display: grid;
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: rgb(230, 0, 0);
    --orange: #fd7702;
    --yellow: #ffbb00;
    --accent: var(--red);
    --un-rotate: 0;
    --un-rotate-x: 0;
    --un-rotate-y: 0;
    --un-rotate-z: 0;
    --un-scale-x: 1;
    --un-scale-y: 1;
    --un-scale-z: 1;
    --un-skew-x: 0;
    --un-skew-y: 0;
    --un-translate-x: 0;
    --un-translate-y: 0;
    --un-translate-z: 0;
    --un-pan-x: ;
    --un-pan-y: ;
    --un-pinch-zoom: ;
    --un-scroll-snap-strictness: proximity;
    --un-ordinal: ;
    --un-slashed-zero: ;
    --un-numeric-figure: ;
    --un-numeric-spacing: ;
    --un-numeric-fraction: ;
    --un-border-spacing-x: 0;
    --un-border-spacing-y: 0;
    --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
    --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
    --un-shadow-inset: ;
    --un-shadow: 0 0 rgb(0 0 0 / 0);
    --un-ring-inset: ;
    --un-ring-offset-width: 0px;
    --un-ring-offset-color: #fff;
    --un-ring-width: 0px;
    --un-ring-color: rgb(147 197 253 / .5);
    --un-blur: ;
    --un-brightness: ;
    --un-contrast: ;
    --un-drop-shadow: ;
    --un-grayscale: ;
    --un-hue-rotate: ;
    --un-invert: ;
    --un-saturate: ;
    --un-sepia: ;
    --un-backdrop-blur: ;
    --un-backdrop-brightness: ;
    --un-backdrop-contrast: ;
    --un-backdrop-grayscale: ;
    --un-backdrop-hue-rotate: ;
    --un-backdrop-invert: ;
    --un-backdrop-opacity: ;
    --un-backdrop-saturate: ;
    --un-backdrop-sepia:
}

hr {
    height: 0;
    color: var(--accent) !important;
    border-top-width: 1px;
    opacity: 1;
    border-color: var(--accent) !important;
}



abbr:where([title]) {
    text-decoration: underline dotted
}

#root {
    background-color: rgb(17 17 17);
    height: 100vh;
}

b,
strong {
    font-weight: bolder
}

code,
kbd,
samp,
pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0
}

button,
select {
    text-transform: none
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none
}

:-moz-focusring {
    outline: auto
}

:-moz-ui-invalid {
    box-shadow: none
}

progress {
    vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0
}

fieldset {
    margin: 0;
    padding: 0
}

legend {
    padding: 0
}

ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0
}

textarea {
    resize: vertical
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af
}

button,
[role=button] {
    cursor: pointer
}

:disabled {
    cursor: default
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle
}

img,
video {
    max-width: 100%;
    height: auto
}

[hidden] {
    display: none
}

*,
:before,
:after {
    --un-rotate: 0;
    --un-rotate-x: 0;
    --un-rotate-y: 0;
    --un-rotate-z: 0;
    --un-scale-x: 1;
    --un-scale-y: 1;
    --un-scale-z: 1;
    --un-skew-x: 0;
    --un-skew-y: 0;
    --un-translate-x: 0;
    --un-translate-y: 0;
    --un-translate-z: 0;
    --un-pan-x: ;
    --un-pan-y: ;
    --un-pinch-zoom: ;
    --un-scroll-snap-strictness: proximity;
    --un-ordinal: ;
    --un-slashed-zero: ;
    --un-numeric-figure: ;
    --un-numeric-spacing: ;
    --un-numeric-fraction: ;
    --un-border-spacing-x: 0;
    --un-border-spacing-y: 0;
    --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
    --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
    --un-shadow-inset: ;
    --un-shadow: 0 0 rgb(0 0 0 / 0);
    --un-ring-inset: ;
    --un-ring-offset-width: 0px;
    --un-ring-offset-color: #fff;
    --un-ring-width: 0px;
    --un-ring-color: rgb(147 197 253 / .5);
    --un-blur: ;
    --un-brightness: ;
    --un-contrast: ;
    --un-drop-shadow: ;
    --un-grayscale: ;
    --un-hue-rotate: ;
    --un-invert: ;
    --un-saturate: ;
    --un-sepia: ;
    --un-backdrop-blur: ;
    --un-backdrop-brightness: ;
    --un-backdrop-contrast: ;
    --un-backdrop-grayscale: ;
    --un-backdrop-hue-rotate: ;
    --un-backdrop-invert: ;
    --un-backdrop-opacity: ;
    --un-backdrop-saturate: ;
    --un-backdrop-sepia:
}

::backdrop {
    --un-rotate: 0;
    --un-rotate-x: 0;
    --un-rotate-y: 0;
    --un-rotate-z: 0;
    --un-scale-x: 1;
    --un-scale-y: 1;
    --un-scale-z: 1;
    --un-skew-x: 0;
    --un-skew-y: 0;
    --un-translate-x: 0;
    --un-translate-y: 0;
    --un-translate-z: 0;
    --un-pan-x: ;
    --un-pan-y: ;
    --un-pinch-zoom: ;
    --un-scroll-snap-strictness: proximity;
    --un-ordinal: ;
    --un-slashed-zero: ;
    --un-numeric-figure: ;
    --un-numeric-spacing: ;
    --un-numeric-fraction: ;
    --un-border-spacing-x: 0;
    --un-border-spacing-y: 0;
    --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
    --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
    --un-shadow-inset: ;
    --un-shadow: 0 0 rgb(0 0 0 / 0);
    --un-ring-inset: ;
    --un-ring-offset-width: 0px;
    --un-ring-offset-color: #fff;
    --un-ring-width: 0px;
    --un-ring-color: rgb(147 197 253 / .5);
    --un-blur: ;
    --un-brightness: ;
    --un-contrast: ;
    --un-drop-shadow: ;
    --un-grayscale: ;
    --un-hue-rotate: ;
    --un-invert: ;
    --un-saturate: ;
    --un-sepia: ;
    --un-backdrop-blur: ;
    --un-backdrop-brightness: ;
    --un-backdrop-contrast: ;
    --un-backdrop-grayscale: ;
    --un-backdrop-hue-rotate: ;
    --un-backdrop-invert: ;
    --un-backdrop-opacity: ;
    --un-backdrop-saturate: ;
    --un-backdrop-sepia:
}

@font-face {
    font-family: Cabinet Grotesk;
    src: url(//cdn.fontshare.com/wf/J2PZYZURCR7HNQKXCZ4VXYA3K5FFCCLT/PZSZLWHMBCE7FFGOUYDEU33FAESUMA3X/JFDL5FBAQ2WMYL3LGKSCZKAIFCS2UQ63.woff2) format("woff2"), url(//cdn.fontshare.com/wf/J2PZYZURCR7HNQKXCZ4VXYA3K5FFCCLT/PZSZLWHMBCE7FFGOUYDEU33FAESUMA3X/JFDL5FBAQ2WMYL3LGKSCZKAIFCS2UQ63.woff) format("woff"), url(//cdn.fontshare.com/wf/J2PZYZURCR7HNQKXCZ4VXYA3K5FFCCLT/PZSZLWHMBCE7FFGOUYDEU33FAESUMA3X/JFDL5FBAQ2WMYL3LGKSCZKAIFCS2UQ63.ttf) format("truetype");
    font-weight: 100 900;
    font-display: swap;
    font-style: normal
}

@font-face {
    font-family: Satoshi;
    src: url(//cdn.fontshare.com/wf/NWBQYJIM7GCZ5XWD7D26ARB3VDY55ZRT/K63EV2KZIGKLE7RANQ2U42S6SVHU5RJ7/X6XYTKIVDUW7GZTZPZNN4EUM5KH54KHF.woff2) format("woff2"), url(//cdn.fontshare.com/wf/NWBQYJIM7GCZ5XWD7D26ARB3VDY55ZRT/K63EV2KZIGKLE7RANQ2U42S6SVHU5RJ7/X6XYTKIVDUW7GZTZPZNN4EUM5KH54KHF.woff) format("woff"), url(//cdn.fontshare.com/wf/NWBQYJIM7GCZ5XWD7D26ARB3VDY55ZRT/K63EV2KZIGKLE7RANQ2U42S6SVHU5RJ7/X6XYTKIVDUW7GZTZPZNN4EUM5KH54KHF.ttf) format("truetype");
    font-weight: 300 900;
    font-display: swap;
    font-style: normal
}

@font-face {
    font-family: Zodiak;
    src: url(//cdn.fontshare.com/wf/UDGW4K5KDO2WNP2256GU5CP7HQDVADLQ/LML445TYWQT7TRPDYZPCIVB3BJWX7NTD/2O5TXCJMYUDHI5FO6N4PXN6WNROSBHNU.woff2) format("woff2"), url(//cdn.fontshare.com/wf/UDGW4K5KDO2WNP2256GU5CP7HQDVADLQ/LML445TYWQT7TRPDYZPCIVB3BJWX7NTD/2O5TXCJMYUDHI5FO6N4PXN6WNROSBHNU.woff) format("woff"), url(//cdn.fontshare.com/wf/UDGW4K5KDO2WNP2256GU5CP7HQDVADLQ/LML445TYWQT7TRPDYZPCIVB3BJWX7NTD/2O5TXCJMYUDHI5FO6N4PXN6WNROSBHNU.ttf) format("truetype");
    font-weight: 100 900;
    font-display: swap;
    font-style: normal
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
}

.pointer-events-none {
    pointer-events: none
}

.visible {
    visibility: visible
}

.absolute {
    position: absolute
}

.fixed {
    position: fixed
}

.relative {
    position: relative
}

.after\:absolute:after {
    position: absolute
}

.bottom-\[-20px\] {
    bottom: -20px
}

.bottom-0 {
    bottom: 0
}

.left-0 {
    left: 0
}

.left-1\/2 {
    left: 50%
}

.right-\[-110px\] {
    right: -110px
}

.right-0 {
    right: 0
}

.top-0 {
    top: 0
}

.after\:-bottom-2:after {
    bottom: -.5rem
}

.after\:left-1\/2:after {
    left: 50%
}

.z-\[-1\] {
    z-index: -1
}

.z-10 {
    z-index: 10
}

.z-50 {
    z-index: 50
}

.after\:z-20:after {
    z-index: 20
}

.grid {
    display: grid
}

.col-span-1 {
    grid-column: span 1/span 1
}

.float-right {
    float: right
}

.m-0 {
    margin: 0
}

.m-auto {
    margin: auto
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.my {
    margin-top: 1rem;
    margin-bottom: 1rem
}

.my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
}

.my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem
}

.mb-2 {
    margin-bottom: .5rem
}

.mb-8 {
    margin-bottom: 2rem
}

.me {
    margin-inline-end: 1rem
}

.mt-1 {
    margin-top: .25rem
}

.mt-2 {
    margin-top: .5rem
}

.mt-4 {
    margin-top: 1rem
}

.inline {
    display: inline
}


.inline-block {
    display: inline-block
}

.hidden {
    display: none
}

.h-\[calc\(100\%-28px\)\] {
    height: calc(100% - 28px)
}

.h-3 {
    height: .75rem
}

.h-4 {
    height: 1rem
}

.h-6,
.h6 {
    height: 1.5rem
}

.h-full {
    height: 100%
}

.h-screen {
    height: 100vh
}

.max-h-\[300px\] {
    max-height: 300px
}

.max-h-\[50px\] {
    max-height: 50px
}

.max-h-\[70px\] {
    max-height: 70px
}

.max-w-3xl {
    max-width: 48rem
}

.max-w-6xl {
    max-width: 72rem
}

.w-3 {
    width: .75rem
}

.w-50 {
    width: 12.5rem
}

.w-auto {
    width: auto
}

.w-fit {
    width: fit-content
}

.w-full {
    width: 100%
}

.w-max {
    width: max-content
}

.w-screen {
    width: 100vw
}

.after\:h-4:after {
    height: 1rem
}

.after\:w-4:after {
    width: 1rem
}

.flex {
    display: flex
}

.inline-flex {
    display: inline-flex
}

.flex-none {
    flex: none
}

.flex-col {
    flex-direction: column
}

.perspective-1200 {
    -webkit-perspective: 1200px;
    perspective: 1200px
}

.-translate-x-1\/2 {
    --un-translate-x: -50%;
    transform: translate(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotate(var(--un-rotate-z)) skew(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z))
}

.-translate-y-24 {
    --un-translate-y: -6rem;
    transform: translate(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotate(var(--un-rotate-z)) skew(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z))
}

.group:hover .group-hover\:-translate-y-1 {
    --un-translate-y: -.25rem;
    transform: translate(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotate(var(--un-rotate-z)) skew(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z))
}

.group:hover .group-hover\:translate-x-1 {
    --un-translate-x: .25rem;
    transform: translate(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotate(var(--un-rotate-z)) skew(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z))
}

.active\:translate-x-\[3px\]:active {
    --un-translate-x: 3px;
    transform: translate(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotate(var(--un-rotate-z)) skew(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z))
}

.active\:translate-y-\[3px\]:active {
    --un-translate-y: 3px;
    transform: translate(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotate(var(--un-rotate-z)) skew(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z))
}

.after\:-translate-x-1\/2:after {
    --un-translate-x: -50%;
    transform: translate(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotate(var(--un-rotate-z)) skew(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z))
}

.after\:rotate-45:after {
    --un-rotate-x: 0;
    --un-rotate-y: 0;
    --un-rotate-z: 0;
    --un-rotate: 45deg;
    transform: translate(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotate(var(--un-rotate-z)) skew(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z))
}

.transform {
    transform: translate(var(--un-translate-x)) translateY(var(--un-translate-y)) translateZ(var(--un-translate-z)) rotate(var(--un-rotate)) rotateX(var(--un-rotate-x)) rotateY(var(--un-rotate-y)) rotate(var(--un-rotate-z)) skew(var(--un-skew-x)) skewY(var(--un-skew-y)) scaleX(var(--un-scale-x)) scaleY(var(--un-scale-y)) scaleZ(var(--un-scale-z))
}

@keyframes ping {
    0% {
        transform: scale(1);
        opacity: 1
    }

    75%,
    to {
        transform: scale(2);
        opacity: 0
    }
}

.animate-ping {
    animation: ping 1s cubic-bezier(0, 0, .2, 1) infinite
}

.cursor-pointer {
    cursor: pointer
}

.select-none {
    -webkit-user-select: none;
    user-select: none
}

.list-disc {
    list-style-type: disc
}

.list-inside {
    list-style-position: inside
}

.items-start {
    align-items: flex-start
}

.items-center {
    align-items: center
}

.justify-start {
    justify-content: flex-start
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.gap-2 {
    gap: .5rem
}

.gap-4 {
    gap: 1rem
}



.whitespace-normal {
    white-space: normal
}

.whitespace-nowrap {
    white-space: nowrap
}

.b,
.border,
.border-1 {
    border-width: 1px
}

.border-b-1 {
    border-bottom-width: 1px
}

.border-darkslate-100 {
    --un-border-opacity: 1;
    border-color: rgb(44 44 44 / var(--un-border-opacity))
}

.border-neutral-600 {
    --un-border-opacity: 1;
    border-color: rgb(82 82 82 / var(--un-border-opacity))
}

.border-primary-500,
.hover\:border-primary-500:hover {
    --un-border-opacity: 1;
    border-color: rgb(230 57 70 / var(--un-border-opacity))
}

.border-b-neutral-400 {
    --un-border-opacity: 1;
    --un-border-bottom-opacity: var(--un-border-opacity);
    border-bottom-color: rgb(163 163 163 / var(--un-border-bottom-opacity))
}

.privacy-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--accent);
    z-index: 2;
}

.progress {
    position: fixed;
    left: 0;
    right: 0;
    height: 5px;
    background: var(--accent);
    bottom: 100px;
}

#progress {
    position: fixed;
    top: 20px;
    left: 20px;
    transform: rotate(-90deg);
}

#progress .indicator {
    stroke: var(--accent);
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: var(--background);
    -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
    background: #fff3;
}

.title-container {
    color: #fff;
}

/* Overlay styles */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.overlay-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80vw;
    max-width: 600px;
    text-align: center;
}

.overlay-content h2 {
    margin-bottom: 15px;
}

.overlay-content p {
    margin-bottom: 20px;
}

.overlay-content button {
    padding: 10px 20px;
    background-color: #1c1c1e;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.rounded {
    border-radius: .25rem
}

.rounded-full {
    border-radius: 9999px
}

.rounded-lg {
    border-radius: .5rem
}

.border-dashed {
    border-style: dashed
}

.border-solid {
    border-style: solid
}

.bg-black {
    background-color: rgb(0 0 0)
}

.bg-darkslate-500,
.bg-gray-900,
.bg-neutral-900 {
    background-color: rgb(23 23 23)
}

.bg-darkslate-700 {
    background-color: rgb(17 17 17)
}

.bg-green-400 {
    background-color: rgb(74 222 128)
}

.bg-green-500 {
    background-color: rgb(34 197 94)
}

.hover\:bg-neutral-800:hover {
    background-color: rgb(38 38 38)
}

.hover\:bg-primary-500\/30:hover {
    background-color: #e639464d
}

.hover\:bg-slate-900:hover {
    background-color: rgb(15 23 42)
}

.after\:bg-black:after {
    background-color: rgb(0 0 0)
}

.p-2 {
    padding: .5rem
}

.p-6 {
    padding: 1.5rem
}

.p-8 {
    padding: 2rem
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.pl-5 {
    padding-left: 1.25rem
}

.pt-4 {
    padding-top: 1rem
}

.text-center {
    text-align: center
}

.text-right {
    text-align: right
}

.align-start {
    vertical-align: top
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
}

.text-5xl {
    font-size: 3rem;
    line-height: 1
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}

.text-xs {
    font-size: .75rem;
    line-height: 1rem
}

.font-black {
    font-weight: 900
}

.font-bold {
    font-weight: 700
}

.font-light {
    font-weight: 300
}

.font-semibold {
    font-weight: 600
}

.font-serif {
    font-family: Zodiak, ui-serif, Georgia, Cambria, Times New Roman, Times, serif
}

.uppercase {
    text-transform: uppercase
}

.tabular-nums {
    --un-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--un-ordinal) var(--un-slashed-zero) var(--un-numeric-figure) var(--un-numeric-spacing) var(--un-numeric-fraction)
}

.text-gray-200 {
    --un-text-opacity: 1;
    color: rgb(229 229 229 / var(--un-text-opacity))
}

.text-gray-500 {
    --un-text-opacity: 1;
    color: rgb(115 115 115 / var(--un-text-opacity))
}

.text-neutral-100 {
    --un-text-opacity: 1;
    color: rgb(245 245 245 / var(--un-text-opacity))
}

.text-neutral-50 {
    --un-text-opacity: 1;
    color: rgb(250 250 250 / var(--un-text-opacity))
}

.text-white {
    --un-text-opacity: 1;
    color: rgb(255 255 255 / var(--un-text-opacity))
}

.group:hover .group-hover\:text-primary-500 {
    --un-text-opacity: 1;
    color: rgb(230 57 70 / var(--un-text-opacity))
}

.hover\:text-neutral-400:hover {
    --un-text-opacity: 1;
    color: rgb(163 163 163 / var(--un-text-opacity))
}

.hover\:text-primary-500:hover {
    --un-text-opacity: 1;
    color: rgb(230 57 70 / var(--un-text-opacity))
}

.decoration-none {
    text-decoration: none
}

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.tab {
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4
}

.opacity-50 {
    opacity: .5
}

.opacity-75 {
    opacity: .75
}

.shadow-custom {
    --un-shadow: 2px 2px #E63946;
    box-shadow: var(--un-ring-offset-shadow), var(--un-ring-shadow), var(--un-shadow)
}

.shadow-lg {
    --un-shadow: var(--un-shadow-inset) 0 10px 15px -3px var(--un-shadow-color, rgb(0 0 0 / .1)), var(--un-shadow-inset) 0 4px 6px -4px var(--un-shadow-color, rgb(0 0 0 / .1));
    box-shadow: var(--un-ring-offset-shadow), var(--un-ring-shadow), var(--un-shadow)
}

.active\:shadow-none:active {
    --un-shadow: 0 0 var(--un-shadow-color, rgb(0 0 0 / 0));
    box-shadow: var(--un-ring-offset-shadow), var(--un-ring-shadow), var(--un-shadow)
}

.after\:shadow-custom:after {
    --un-shadow: 2px 2px #E63946;
    box-shadow: var(--un-ring-offset-shadow), var(--un-ring-shadow), var(--un-shadow)
}

.transition {
    transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.transition-colors {
    transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.duration-100 {
    transition-duration: .1s
}

.duration-75 {
    transition-duration: 75ms
}

.ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.after\:content-\[\'\'\]:after {
    content: ""
}

.app-container {
    background-color: #1e1e1e;
    color: #fff;
    font-family: 'Arial', sans-serif;
    min-height: 100vh;
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.main-content {
    max-width: 1200px;
    width: 100%;
    padding: 20px;

    overflow: auto;
    display: grid !important;
}



.content {
    display: inline-block;
    width: 100%;
    break-inside: avoid;
    margin-bottom: 20px;
    /* Same as column-gap */
}

@media (min-width: 768px) {
    .content-grid {
        grid-template-columns: 1fr 1fr;
        /* Two columns for larger screens */
        grid-auto-rows: minmax(150px, auto);
        /* Automatically calculate row heights */
    }

    .row-span-2 {
        grid-row: span 2;
        /* Span two rows for larger screens */
    }

    .col-span-1 {
        grid-column: span 1;
        /* Span one column for larger screens */
    }
}

@media (min-width: 640px) {
    .sm\:gap-2 {
        gap: .5rem
    }

    .sm\:p-4 {
        padding: 1rem
    }
}

@media (min-width: 768px) {
    .md\:relative {
        position: relative
    }

    .md\:bottom-auto {
        bottom: auto
    }

    .md\:right-auto {
        right: auto
    }

    .md\:z-auto {
        z-index: auto
    }

    .md\:col-span-1 {
        grid-column: span 1/span 1
    }

    .md\:col-span-2 {
        grid-column: span 2/span 2
    }

    .md\:col-span-3 {
        grid-column: span 3/span 3
    }

    .md\:row-span-1 {
        grid-row: span 1/span 1
    }

    .md\:row-span-2 {
        grid-row: span 2/span 2
    }

    .md\:row-span-3 {
        grid-row: span 3/span 3
    }

    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .md\:h-screen {
        height: 100vh
    }

    .md\:max-h-\[300px\] {
        max-height: 300px
    }

    .md\:gap-3 {
        gap: .75rem
    }

    .md\:p-6 {
        padding: 1.5rem
    }

    .md\:text-base {
        font-size: 1rem;
        line-height: 1.5rem
    }

    .md\:opacity-100 {
        opacity: 1
    }
}

@media (min-width: 1024px) {
    .lg\:col-span-2 {
        grid-column: span 2/span 2
    }

    .lg\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }

    .lg\:h-screen {
        height: 100vh
    }

    .lg\:max-h-\[800px\] {
        max-height: 800px
    }

    .lg\:gap-4 {
        gap: 1rem
    }
}

@media (min-width: 1280px) {
    .xl\:w-100 {
        width: 25rem
    }

    .xl\:text-5xl {
        font-size: 3rem;
        line-height: 1
    }
}


body {
    margin: 0;
    font-family: Satoshi, sans-serif;
    -webkit-font-smoothing: antialiased;
    overflow: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Satoshi,
        serif, sans-serif
}

p {
    font-family: Satoshi;
}

b {
    font-weight: 700 !important
}