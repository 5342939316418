.cards-grid2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

p {
    font-size: unset !important;
    line-height: unset !important;
}

.card2 {
    background-color: #2b2b2b;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    /* Flexbox for vertical alignment */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    /* Ensure the card takes up the full height */
}

.card2:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card2 h3 {
    margin: 0 0 10px 0;
    font-size: 1.5rem;
}

.card2 p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5;
}

/* Card footer (link section) to stay at the bottom */
.card2-footer {
    margin-top: auto;
    /* This pushes the footer to the bottom of the card */
}

.card2-footer a {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #1e90ff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.card2-footer a:hover {
    background-color: #3391ff;
}

/* Optional styling for horizontal rule */
.card2-footer hr {
    border-color: rgba(230, 57, 70, 0.3);
    /* Matches the color from your example */
    margin-top: 10px;
    margin-bottom: 10px;
    border-width: 1px;
}